@import '../../../assets/scss/main.scss';

.auth-layout {
  min-height: 100vh !important;
  height: 100% !important;

  .auth-sider {
    min-height: min-content;

    .sider-container {
      @include fade-in();
      text-align: center;

      .ant-menu {
        text-align: left;

        &-item {
          font-weight: 500;
          letter-spacing: 0.2px;
          font-family: $font-header;
        }

        .ant-menu-sub {
          background: #001529 !important;
        }
      }
    }

    .ant-layout-sider-zero-width-trigger {
      top: 12px !important;
      right: -42px !important;
      width: 42px !important;
      height: 42px !important;
      text-align: center !important;

      &:hover {
        background: #001529 !important;

        i {
          transition: 400ms;
          color: color(primary) !important;
        }
      }

      i {
        transition: 400ms;
        margin: 11.5px auto;
      }
    }

    .ant-layout-sider-children {
      .ant-divider {
        background: darken(color(primary), 30%) !important;
      }

      .ant-divider-horizontal {
        margin: 1em auto !important;
        border-top: 1px solid darken(color(primary), 25%) !important;
      }
    }
  }

  .ant-layout-footer {
    text-align: center;
    margin: 1em auto;
    padding: 0;
  }
}

.menu-logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.user-info {
  .avatar {
    background-color: color(primary) !important;
    color: white !important;
    margin: 1.25em 0 0.75em 0 !important;
    text-align: left !important;

    @extend .noselect;
    @include fade-in();
  }

  h4 {
    margin-bottom: 0em;
    color: white;
    @include fade-in();
  }

  h5 {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 1.5em;
    @include fade-in();
  }
}

.auth-layout-content {
  margin: 0 16px;
  text-align: left;

  @media only screen and (max-width: 558px) {
    padding: 0;
    margin: 0;

    .ant-breadcrumb {
      margin-left: 1em !important;
    }

    .children-container {
      border-radius: 0.25em;
      box-shadow: 1px 1px 4px 0px #ccc;
      padding: 1em !important;
    }
  }
}
