@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in($seconds: 0.75s) {
  -webkit-animation: fadein $seconds; // Safari, Chrome and Opera > 12.1
  -moz-animation: fadein $seconds; // Firefox < 16
  -ms-animation: fadein $seconds; // Internet Explorer
  -o-animation: fadein $seconds; // Opera < 12.1
  animation: fadein $seconds;
  opacity: 1;

  -webkit-transition: opacity 600ms linear;
  transition: opacity 600ms linear;
}

.fade-in {
  @include fade-in();
}

.fade-out {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fade-in {
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
