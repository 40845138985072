@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;1,600&display=swap');

@font-face {
  font-family: 'Dhivehi';
  src: local('Dhivehi'), url(../../assets/fonts/DAM_Madheeh.otf) format('opentype');
}

.mv {
  font-family: 'Dhivehi' !important;
  direction: rtl !important;
  text-align: right !important;
}

// font-family: 'Roboto', sans-serif;
$font-header: 'Josefin Sans', sans-serif !important;
$font-text: 'Josefin Sans', sans-serif !important;
