.status {
  padding: 0.65em;
  border-radius: 0.3rem;
  border: 1px solid black;
  font-weight: bold;
  font-size: smaller;
}

.status-false {
  @extend .status;
  background-color: #fdcb6e;
}

.status-true {
  @extend .status;
  background-color: #0984e3;
}
