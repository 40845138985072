@import './animations.scss';
@import './fonts.scss';
@import './animations.scss';
@import './fonts.scss';

// Antd Components

// Alert
.ant-alert-close-icon:focus {
  outline: none !important;
}

// Form
.ant-form-input-number {
  min-width: 100% !important;
}

// Select
.ant-select-selection-placeholder,
.ant-select {
  text-align: left !important;
}

.ant-select-text-align-left {
  text-align: left !important;
}

.ant-select-selection-item {
  color: black !important;
}

// Divider
.ant-divider-horizontal {
  margin: 1em 0 !important;
}

// Upload
.ant-upload-list-picture-card {
  display: inline !important;
}

// Table
.ant-table-row-anchor {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #339cff;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 1em auto !important;
}

.ant-table-cell-render {
  margin-bottom: 0 !important;

  @media only screen and (max-width: 558px) {
    font-size: 7pt !important;
  }
}

.ant-table-cell {
  @media only screen and (max-width: 558px) {
    font-size: 7pt !important;
  }
}

.ant-table.ant-table-bordered thead > tr {
  th {
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 0.9em !important;
    letter-spacing: 0.2px !important;
    font-family: $font-header !important;
    background: lighten(color(secondary), 5%) !important;
    color: white !important;

    &:not(:last-child) {
      border-right: 0 !important;
    }

    .ant-table-column-sorters:hover,
    .ant-table-filter-trigger-container:hover {
      background: lighten(color(secondary), 10%) !important;
    }
  }
}

.ant-table-actions-container {
  max-width: 140px !important;
  display: inline-flex !important;
}

.ant-table.ant-table-bordered tbody {
  tr {
    td {
      &:not(:last-child) {
        border-right: 0 !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #eee !important;
      transition: none;
    }
  }
}

.ant-table-text-align-center {
  text-align: center !important;
}

.ant-table-name-and-customfields {
  .name {
    font-weight: 700;
    color: black;
    margin-bottom: 0.25em;
  }

  .customfield-wrapper {
    .customfield {
      font-size: 10px;
      margin-bottom: 0 !important;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #eee;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-btn-link {
  padding: 0 !important;
  height: fit-content !important;
  font-weight: bold !important;
}

.ant-tag {
  flex-wrap: 'wrap' !important;
}

.ant-table-column-sort {
  background-color: #eee !important;
}
