@import '../../../assets/scss/main.scss';

@keyframes auth-spinner {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

@-webkit-keyframes auth-spinner {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.auth-spinner {
  @include fade-in();
  position: absolute;
  width: 100%;
  height: 100%;
  top: calc(50vh - 100px);
  left: calc(50vw - 100px);
}

.auth-spinner div {
  box-sizing: content-box;
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: auth-spinner 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: auth-spinner 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.auth-spinner div:nth-child(1) {
  border-color: #003569;
}

.auth-spinner div:nth-child(2) {
  border-color: #0054a5;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.auth-spinner {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
