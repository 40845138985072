@import '../scss/colors';

.btn-auth {
  transition: 500ms !important;
  border-radius: 20px;
  border: 1px solid color(primary);
  background-color: color(primary);
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    transition: 500ms;
    background-color: lighten(color(primary), 5%);
  }

  &:focus {
    outline: none;
  }

  &.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }

  &:disabled {
    border: 1px solid darken(lightgrey, 5%);
    background-color: lightgrey;
    cursor: not-allowed;
  }
}

.btn-add {
  color: grey !important;
  border-color: lightgrey !important;

  &:hover {
    color: darken(color(success), 40%) !important;
    border-color: darken(color(success), 15%) !important;
  }

  i {
    font-size: 14pt;
  }
}

.btn-delete,
.btn-error {
  background: transparent !important;
  border: none !important;
  width: fit-content !important;
  padding: 0 !important;
  height: fit-content !important;
  color: darken(color(error), 15%) !important;
  margin: auto 1em;

  span {
    font-size: 16px;
  }
}

.btn-active,
.btn-inactive,
.btn-edit,
.btn-info {
  background: transparent !important;
  border: none !important;
  width: fit-content !important;
  padding: 0 !important;
  height: fit-content !important;
  color: darken(color(primary), 15%) !important;
  margin: auto 1em;

  span {
    font-size: 16px;
  }
}

.btn-active {
  color: $color-success !important;
}

.btn-inactive {
  color: #666666 !important;
}

.btn-list-remove {
  transition: 300ms;
  border: 1px solid transparent;
  background-color: lightgrey;
  padding: 0.75em;
}

.btn-right {
  margin-left: 0.5em !important;
}
