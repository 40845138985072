@import '../../../assets/scss/main.scss';

@mixin blue-gradient() {
  background: rgb(24, 144, 255);
  background: -moz-linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  background: -webkit-linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  background: linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1890ff",endColorstr="#44a7ff",GradientType=1);
}

.container {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overflow-y: hidden !important;

  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;

    &.login-container {
      left: 0;
      width: 50%;
      z-index: 2;
    }

    &.register-container {
      left: 0;
      width: 50%;
      opacity: 0;
      z-index: 1;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;

    .overlay {
      @include blue-gradient();

      background-size: cover;
      background-position: 0 0;
      color: #ffffff;
      position: relative;
      left: -100%;
      height: 100%;
      width: 200%;
      transform: translateX(0);
      transition: transform 0.6s ease-in-out;

      .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;

        &.overlay-left {
          transform: translateX(-2.5%);
        }

        &.overlay-right {
          right: 0;
          transform: translateX(0);
        }
      }
    }
  }

  &.right-panel-active {
    .login-container {
      transform: translateX(100%);
    }

    .register-container {
      transform: translateX(100%);
      opacity: 1;
      z-index: 5;
      animation: show 0.6s;
    }

    .overlay-container {
      transform: translateX(-100%);
    }

    .overlay {
      transform: translateX(50%);
    }

    .overlay-left {
      transform: translateX(0);
    }

    .overlay-right {
      transform: translateX(20%);
    }
  }
}

.form-auth {
  @include fade-in();

  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding: 0 4em !important;
  height: 100% !important;
  text-align: center !important;

  .ant-form-item {
    width: 100%;
    margin-bottom: 1em;

    .ant-form-item-explain {
      margin-top: 2px;
      text-align: left;
    }
  }

  h1 {
    font-weight: bold !important;
    margin: 0 0 1em 0 !important;
  }

  span {
    font-size: 13px !important;
  }

  a {
    color: #333 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    margin: 15px 0 !important;
  }

  .btn-auth {
    margin-top: 2em !important;
  }

  @media only screen and (max-width: 768px) {
    padding: 2em 6em !important;
    width: 100vw !important;
    height: calc(100vh - 64px) !important;
  }
}

.mobile-prompts-container {
  h1 {
    font-weight: bold;
    margin: 0;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }

  @media only screen and (max-width: 768px) {
    @include fade-in();
    @include blue-gradient();

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100vw;
    padding: 2em;
    color: white;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
