@import './assets/scss/main.scss';

body,
.App {
  margin: 0;
  position: relative;

  font-family: font(font-text);
  color: color(primary-text);

  background: color(primary) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: font(font-header);
  }

  label {
    font-family: font(font-header);
    font-weight: bold;
    text-transform: uppercase;
  }

  span,
  p {
    font-family: font(font-text);
  }
}
