@import '../../../assets/scss/main.scss';

.auth-content {
  @include fade-in();
  position: relative;

  .ant-breadcrumb {
    margin: 1em 0 !important;
  }

  .ant-spin {
    position: absolute !important;
    top: 50% !important;
    right: 50% !important;
  }

  > .app-content-header {
    font-size: 24pt !important;
  }

  .children-container {
    border-radius: 0.25em;
    box-shadow: 1px 1px 4px 0px #ccc;
    padding: 1.5em;
    background: #fff;
    min-height: 85vh;
  }
}
