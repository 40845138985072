@import '../../../assets/scss/main.scss';

.home-layout {
  .ant-layout-header {
    padding: 0 3em !important;
  }

  .home-header {
    height: 64px;
    @include fade-in();

    .logo {
      float: left;
      height: 48px;
      width: 48px;
      margin: 8px 16px 0 0;
    }

    .app-name {
      transition: 400ms;
      border-top: 1px solid transparent;
      white-space: nowrap;
      text-align: center;
      height: 64px;
      float: left;
      padding: 0;

      .header-title {
        text-align: left;
        font-family: font(primary);
        font-size: 24pt;
        margin: 0;
        color: whitesmoke;

        @extend .noselect;

        span {
          font-size: 28pt;
          color: color(primary);

          margin: 0;
        }
      }

      &:hover {
        transition: 400ms;
        border-top: 1px solid color(accent);
      }
    }
  }

  .ant-layout-footer {
    text-align: center;
    padding: 0.5em;
  }
}
