$primary-color: #209cee;

// Color Scheme
$color: (
  accent: lighten($primary-color, 30%),
  primary: $primary-color,
  primary-dark: #001529,
  secondary: #001529,
  primary-text: black,
  secondary-text: white,
  success: #12cf51,
  orange: #ffc069,
  error: #c71d25,
);

$color-danger: #c71d25;
.danger-icon {
  color: $color-danger !important;
}

$color-success: #12cf51;
.success-icon {
  color: $color-success !important;
}

$color-warning: #ffc069;
.warning-icon {
  color: $color-warning !important;
}

.edit-icon {
  color: darken($primary-color, 15%) !important;
}

@function color($key: 'primary') {
  @return map-get($color, $key);
}

@mixin blue-gradient() {
  background: rgb(24, 144, 255);
  background: -moz-linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  background: -webkit-linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  background: linear-gradient(39deg, rgba(24, 144, 255, 1) 0%, rgba(68, 167, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1890ff",endColorstr="#44a7ff",GradientType=1);
}
