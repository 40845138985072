@import '../../../assets/scss/fonts';

.editor {
  margin-top: 1em;
  min-height: 500px;

  .spinner {
    margin-top: -50px;
  }
}
