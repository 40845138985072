.filter-dropdown {
  padding: 0.75em;

  .search-input {
    width: 100%;
    margin-bottom: 0.75em;
    display: block;
  }

  .search-btn,
  .reset-btn {
    width: 85px;
  }

  .search-btn {
    margin-right: 5px;
  }
}
